<template>
  <a-upload
    name="file"
    class="avatar-uploader"
    :show-upload-list="false"
    :action="upLoadObj.action"
    :before-upload="beforeUpload"
    @change="handleChange"
  >
    <a-button size="small" type="link"> <a-icon type="upload" /> 设置头像 </a-button>
  </a-upload>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/8/29 16:32
 * @version 1.0
 * @description 上传头像组件
 */
import {
  beforeUpload,
  getBaseCallback64,
  headers,
  upLoadObj,
} from '@/utils/utils'
import { editUserData } from '@/api/Login'
import { mapGetters } from 'vuex'
export default {
  name: 'UploadAvatar',
  data() {
    return {
      beforeUpload,
      headers,
      upLoadObj,
      loading: false,
      imageUrl: ''
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    handleChange (info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        // 1.回调函数可以带上 imageUrl 本地转换的路径
        getBaseCallback64(info.file.originFileObj, () => {
          this.$emit('changeImg', info.file.response.link)
          this.imageUrl = info.file.response.link
          this.loading = false
          editUserData({ avatar: this.imageUrl, id: this.userInfo.id, username: this.userInfo.username }).then((res) => {
            this.$store.dispatch('GetUserInfo')
          })
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
