<template>
  <a-modal
    :centered="true"
    :confirm-loading="confirmLoading"
    :visible="visible"
    title="编辑个人信息"
    @cancel="closeModal"
    @ok="handleOk"
  >
    <a-form :form="form" v-bind="layout">
      <a-form-item label="生日">
        <a-date-picker
          v-decorator="['birth']"
          format="YYYY-MM-DD"
          size="small"
          valueFormat="YYYY-MM-DD"/>
      </a-form-item>
      <a-form-item label="性别">
        <a-radio-group v-decorator="['sex']" size="small">
          <a-radio :value="1">
            男
          </a-radio>
          <a-radio :value="0">
            女
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="QQ">
        <a-input v-decorator="['qq']" size="small"></a-input>
      </a-form-item>
      <a-form-item label="微信">
        <a-input v-decorator="['wx']" size="small"></a-input>
      </a-form-item>
      <a-form-item label="旺旺">
        <a-input v-decorator="['wangwang']" size="small"></a-input>
      </a-form-item>
    </a-form>

  </a-modal>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/7 9:47
 * @version 1.0
 * @description 修改个人信息弹窗
 */
import ShowModalMixin from '@/mixin/ShowModalMixin'
import pick from 'lodash.pick'
import { editBaseUser } from '@/api/Login'
const fields = ['birth', 'wx', 'qq', 'wangwang', 'sex']
export default {
  name: 'UpdatePersonalDetails',
  mixins: [ ShowModalMixin ],
  props: {
    // 复现数据
    model: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      confirmLoading: false,
      form: this.$form.createForm(this, { name: 'PersonalDetailsForm' }),
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 16 },
      },
    }
  },
  created() {
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.$nextTick(() => {
        fields.forEach(v => this.form.getFieldDecorator(v))
        this.model && this.form.setFieldsValue(pick(this.model, fields))
      })
    })
  },
  methods: {
    handleOk() {
      this.form.validateFields((err, values) => {
        if(!err) {
          this.confirmLoading = true
          editBaseUser(values).then(() => {
            this.$store.dispatch('GetUserInfo').then(() => {
              this.$message.success('编辑个人信息成功')
              this.confirmLoading = false
              this.closeModal()
            })
          })
          console.log(values)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
