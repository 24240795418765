<template>
  <div class="container bg-white p-10">
    <div class="title">
      基本信息
    </div>
    <div class="userinfo_box">
      <div class="left">
        <div>
          <div class="img">
            <img :src="userInfo.avatar">
            <div class="text">
              <div class="text1">你好！</div>
              <div class="text2">{{ datas.businessName }}</div>
            </div>
          </div>
          <UploadAvatar @changeImg="AvatarEmit"></UploadAvatar>
        </div>
      </div>
      <a-button size="small" type="primary" @click="ModalUpdatePersonalDetails = true">编辑</a-button>

    </div>
    <hr style="width: 90%;">
    <div class="right" style="padding: 10px 0 0 60px;font-size: 16px;">
      <div><span class="label">用户名：</span><span class="text-md">{{ datas.businessName }}</span></div>
      <div><span class="label">生日：</span><span class="text-md">{{ userInfo.birth }}</span></div>
      <div><span class="label">性别：</span><span class="text-md">{{ userInfo.sex | sexFilter }}</span></div>
      <div><span class="label">安全手机：</span><span class="text-md">{{ userInfo.phone }}</span></div>
      <div><span class="label">邮箱：</span><span class="text-md">{{ userInfo.email }}</span></div>
      <div><span class="label">QQ：</span><span class="text-md">{{ userInfo.qq }}</span></div>
      <div><span class="label">微信：</span><span class="text-md">{{ userInfo.wx }}</span></div>
      <div><span class="label">旺旺：</span><span class="text-md">{{ userInfo.wangwang }}</span></div>

    </div>

    <UpdatePersonalDetails v-model="ModalUpdatePersonalDetails" :model="userInfo"></UpdatePersonalDetails>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import UploadAvatar from '@/components/UpLoadAvatar/UploadAvatar'
import { getUserInfoRz } from '@/api/Login'
import UpdatePersonalDetails from '@/components/Modal/UpdatePersonalDetails'

export default {
  name: 'UserInfo',
  components: { UpdatePersonalDetails, UploadAvatar },
  data() {
    return {
      ModalUpdatePersonalDetails: false,
      form: {
        avatar: '',
        email: '',
        nickName: ''
      },
      datas: {}
    }
  },
  filters: {
    // 性别 过滤器
    sexFilter(val) {
      if(val === 0) {
        return '女'
      } else if(val === 1){
        return '男'
      } else {
        return '保密'
      }
    },
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  mounted() {
    if (Object.keys(this.userInfo).length === 0 || this.infoProfect === 0 || this.infoProfect === 2) return
    getUserInfoRz().then(res => {
      this.datas = res.data
      console.log(this.datas, 'this.获取标签列表数据')

    })
  },
  methods: {
    AvatarEmit(url) {
      this.form.avatar = url
    }
  }
}
</script>
<style lang="scss" scoped>
.right {
  div {
    height: 40px;
  }

  .label {
    display: inline-block;
    width: 70px;
    font-size: 14px;
    color: #999;
    line-height: 40px;
  }
}

.container {
  position: relative;

  .title {
    font-size: 18px;
    font-weight: 700;
    color: #000;
    margin: 25px 0 0 50px;
  }

  .userinfo_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 40px 0 60px;
    width: 100%;


    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .img {
        display: flex;
        align-items: center;

        img {
          width: 60px;
          height: 60px;
          object-fit: cover;
        }

        .text {
          margin-left: 20px;

          .text1 {
            color: #707070;
            font-size: 12px;
            font-weight: 400;
          }

          .text2 {
            margin-top: 5px;

            color: #202124;
            font-size: 14px;
            font-weight: 700;
          }
        }
      }

      .header_img {
        display: flex;
        margin-left: 80px;
        align-items: flex-start;

        > div:nth-child(1) {
          width: 50px;
          font-size: .7rem;
          font-weight: 500;
        }

        .upload {
          width: 80px;
          height: 80px;
          border: 1px dashed #87a1c4;
          text-align: center;
          line-height: 80px;
          cursor: pointer;
          color: #4FABFF;
          font-size: 12px;
          font-weight: 400;
          user-select: none;
        }
      }
    }

  }
}
</style>
